.flex{
    display: flex;
}
.integration-settings{
    padding: 10px;
    border: 1px solid #d0d1d6;
    background: #d0d1d62b;
    margin-bottom: 20px;
}

.integration-settings.not-connected{
    background: #fff;
}

.integration-settings .bg {
    width: 70px;
    min-height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg.slack{
    background-image: url('../../public/Slack_icon_2019.svg.png');
}

.bg.intercom{
    background-image: url('../../public/intercom-2.svg');
}

.bg.jira{
    background-image: url('../../public/jira.svg');
}

.bg.notion{
    background-image: url('../../public/notion.svg');
}

.bg.zendesk{
    background-image: url('../../public/zendesk-3.svg');
}

.bg.gong{
    background-image: url('../../public/gong-io-logo.svg');
}

.bg.zendesk{
    background-image: url('../../public/Zendesk_logo.svg') !important;
}

.bg.salesforce{
    background-image: url('../../public/salesforce-logo-0.png') !important;
}

.integration-settings h2{
    text-transform: uppercase;
    margin: 0px;
}

.integration-content{
    margin-left: 20px;
    width: -webkit-fill-available;
    align-self: center;
}

.edit button {
    display: flex;
    padding: 5px 10px;
    border: 1px solid #d0d1d6;
    cursor: pointer;
}

.edit button:hover {
    background: #0000008a;
    border: 1px solid#000;
    color: #fff;
}

.edit button i{
    margin-left: 6px;
}

a.authorize-link {
    place-content: center;
    padding: 5px 10px;
    border: 1px solid;
    background: #efefef;
}

a.authorize-link:hover {
    background: #5c5ce9;
    border: 1px solid #5c5ce9;
    color: #fff;
    font-weight: 500;
}

.slack-channels{
    border-radius: 0px;
    padding: 4px;
    border-color: #cdcdcd;
}

.domain-input-container {
    margin: 20px 0px;
    flex-direction: row;
}