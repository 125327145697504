.checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    background-color: white;
    cursor: pointer;
    position: relative;
    border: 1px solid #CCCCCC ;
    border-radius: 2px;
  }

.checkbox:disabled {
    cursor: not-allowed;
}
  
  .checkbox:checked {
    background-color: #5B60E3;
  }
  
  .checkbox:focus {
    outline: none;
    border: 1px solid #CCCCCC ;
  }