@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.columns-flex {
  display: flex;
  flex-direction: column;
}

.rows-flex {
  display: flex;
  flex-direction: row;
}

 .app-content-page { 
  position: absolute;
  left: 59px;
  top: 0;
  display: flex;
  min-height: 100vh;
}
 
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); 

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400&display=swap');
button {
  border: none;
}

.loading {
  width: 100px;
  height: 100px;
}

.discover-submenu {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  width: 260px;
  height: 100%;
  border-right: 1px solid rgba(22, 24, 50, 0.2);
}

.discover-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 600px;
}

.solutions-submenu {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  width: 241px;
  height: 100%;
  border-right: 1px solid rgba(22, 24, 50, 0.2);
}

.solutions-list {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.solution-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 30px;
  max-width: 650px;
  text-align: left;
}

.solution-list-item {
  display: flex;
  flex-direction: row;
  width: 918px;
  height: 79px;
  background: #FFFFFF;
  border: 1px solid rgba(22, 24, 50, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  align-items: center;
}
.solution-icon {
  width: 33px;
  height: 34px;
}
.bug-icon {
  width: 33px;
  background: url('../public/bug-icon.svg') no-repeat center center;
}
.enhancement-icon {
  width: 33px;
  background: url('../public/enhancement-icon.svg') no-repeat center center;
}
.feature-icon {
  width: 33px;
  background: url('../public/feature-icon.svg') no-repeat center center;
}

.solution-upvotes {
  width: 30px;
  border: 1px solid rgba(22, 24, 50, 0.3);
  border-radius: 5px;
  text-align: center;
  align-items: center;
  background: none;
  cursor: pointer;
}

.solution-name {
  flex-direction: column;
  width: 340px;
  margin-left: 15px;
  font-size: 0.75em;
  justify-items: center;
  align-items: flex-start;

 

}

.solution-name span {
  width: 100%;
}

.solution-name p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 40ch;
  margin: 0px;
}
.solution-name h3{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20ch;
  margin: 0px;
}

.solution-thin-item {
  display: flex;
  flex-direction: column;
  width: 80px;
  font-size: 0.75em;
  align-items: center;
  text-align: center;
  white-space: pre;
}
.solution-medium-item {
  display: flex;
  flex-direction: column;
  font-size: 0.75em;
  width: 110px;
  align-items: center;
  text-align: center;
}

.solution-thin-item span:nth-of-type(1),
.solution-medium-item span:nth-of-type(1){
  font-weight: bold;
}

.solution-progress-icon {
  margin-left: 21px;
  width: 40px;
  height: 40px;
  background: rgba(252, 10, 10, 0.2);
  border: 1px solid #FC0A0A;
  box-sizing: border-box;
  border-radius: 20px;
}


.settings-submenu-item {
  margin-bottom: 15px;
}

.logoutButton {
  height: 32px;
  background-color: #161832;
  border-radius: 5px;
  color: #FFF;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  margin-bottom: 20px;
}

.logoutButton:hover{
  color: #161832;
  background-color: #16183238;
}

.integrations {
  display: flex;
  flex-direction: column;
}
.integrations-list {
  display: flex;
  flex-direction: column;
}


.discover-search {
  display: flex;
  width: 85%;
  height: 30px;
  margin-top: 15px;
  margin-left: 15px;
  border: 1px #161832 solid;
  padding: 5px;
  color: #888888;
}
.discover-results {
  display: flex;
  width: 85%;
  margin-top: 15px;
  margin-left: 15px;
  flex-direction: column;
  padding: 5px;
}

.discover-item {
  border-bottom: 1px #888888 solid;
}

.save-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /*width: 220px;*/
  height: 32px;
  background-color: #161832;
  border-radius: 5px;
  color: #FFF;
  font-weight: bold;
  border: 0;
}

.epic-container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.issue-container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.checkmark {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  margin-right: 10px;
  background: #FFFFFF;
  border: 1px solid rgba(22, 24, 50, 0.5);
  box-sizing: border-box;
}

.break-flex-row {
  flex-basis: 100%;
  height: 0;
}

.jira-items-list {
  margin-top: 15px;
  max-width: 95%;
}

.jira-epic {
  width: 95%;
  height: 50px;
  min-width: 800px;
  margin-bottom: 5px;
  background: rgba(196, 196, 196, 0.2);
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.jira-issue {
  width: 95%;
  height: 50px;
  margin-bottom: 5px;
  min-width: 800px;
  background: rgba(196, 196, 196, 0.2);
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.epic-marker {
  width: 10px;
  height: 50px;
  margin-right: 15px;
  background: rgba(22, 24, 50, 0.6);
}

.issue-marker {
  width: 10px;
  height: 50px;
  margin-right: 15px;
  background: rgba(57, 76, 253, 0.28);
}

.epic-summary {
  font-size: 0.85em;
  width: 250px;
}

.issue-type {
  font-size: 0.75em;
  color: #161832;
  margin-left: 20px;
}

.issue-id {
  font-size: 0.75em;
  color: #161832;
  margin-left: 20px;
  color: #1627FD;
}


.issue-owner {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: 20px;
  margin-right: 10px;
}

.epic-linked {
  margin-left: 20px;
  width: calc(100% - 20px);
  margin-top:5px;
}

.wizard-steps {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 200px;
  height: 30px;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.step {
  display: flex;
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid rgba(22, 24, 50, 0.3);
  box-sizing: border-box;
  border-radius: 15px;
  font-size: 0.85em;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.step-line {
  border-top: 1px solid rgba(22, 24, 50, 0.3);
  height: 1px;
  width: 105px;
  align-items: center
}

.wizard-next {
  position: absolute;
  right: 107px;
  top: 30px;
  width: 54px;
  height: 26px;
  font-size: 0.8em;
  align-self: end;
  background: #9beac2;
  border: 1px solid rgba(22, 24, 50, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

.wizard-steps .selected {
  border: 3px solid #30F3DC;
}
.wizard-steps .selected {
  background: #30F3DC;
  border: 1px solid #30F3DC;
}

.issue-filters {
  border-bottom: 1px solid rgba(22, 24, 50, 0.3);
  width: 95%;
  margin-top: 45px;
  margin-bottom: 25px;
  padding-bottom: 10px;
  padding-top: 10px;

}


.selected {
  font-weight: 700 !important;
  color: rgba(22, 24, 50, 0.8) !important;
}


.preview-box {
  margin-top: 15px;
  /*width: 700px;*/
  /*border: 1px solid rgba(22, 24, 50, 0.3);*/
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 10px;
  margin-bottom: 15px;
}

.preview-box div {
  margin-top: 10px;
  margin-bottom: 10px;
}
.preview-items {
  width: 150px;
}

.top-aligned {
  align-self: flex-start;
}
.issue-filters {

}

.issue-filter {
  margin-right: 30px;
}

.issue-type {
  width: 63px;
}
.issue-type-toggle {
  cursor: pointer;
}

.issue-properties {
  align-items: center;
  margin-bottom: 25px;
}

.issue-property {
  color: rgba(22, 24, 50, 0.6);
  text-align: center;
  font-size: 0.85em;
  width: 75px;
}

.issue-property:first-child {
  width: 280px !important;
  margin-left: 30px;
}

.common-row {
  /*align-items: center;*/
}


.common-row div {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  height: 32px;
  align-items: center;
}

.common-row .short {
  width: 32px;
}

.short {
  width: 32px;
}

.common-row img {
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.edit-button {
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url('../public/editbutton.svg') no-repeat center center;
  background-size: 24px 24px;
}
.red {
  color: red;
}
.delete-button {
  display: flex;
  width: 24px;
  height: 24px;
  color: red;
  cursor: pointer;
  background-color: #FFF;
  align-items: center;
  font-weight: bold;
}

.create-solution-button {
  background: #9CEAC2 url('../public/create-solution-button.svg') no-repeat center center;
  width: 199px;
  height: 172px;
  border-radius: 5px;
  cursor: pointer;
}


/* loader */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 18px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 2px;
  border: 2px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.full-width {
  width: 100%
}

.left-menu {
  /* font-family: 'Inter'; */
  font-size: '200px'; 
  color: 'red';
}

/* end loader */