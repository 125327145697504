.wizard-container {
    position: absolute;
    display: flex;
    z-index: 99;
    top: 50%;
    left: 50%;
    margin-left: calc(85vw / -2);
    margin-top: calc(85vh / -2);
    width: 85vw;
    height: 85vh;
    background-color: #FFFFFF;
    flex-direction: row;
    border-radius: 5px;
}

.wizard-left {
    width: 60%;
}
.wizard-right {
    width: 40%;
}


.overlay {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: rgba(196, 196, 196, 0.6);
    width: 100vw;
    height: 100vh;
}

.title-field-input {
    width: -webkit-fill-available;
    font-size: 24px;
    font-weight: bold;
    height: 35px;
    margin-top: 15px;
}

.title-field {
    width: -webkit-fill-available;
    font-size: 1.5em;
    font-weight: bold;
    /*margin: 0 auto;*/
    margin-bottom: 10px;
    height: 35px;
    padding: 5px;
}

.wizard-title {
    width: 750px;
    margin-top: 35px;
    margin-left: 35px;
    height: 50px;
    border-bottom: 1px solid rgba(22, 24, 50, 0.5);
    padding-bottom: 15px;
}

.delete-solution-button {
    display: flex;
    margin-top: 25px;
    margin-right: 25px;
    height: 35px;
    border: 1px solid red;
    color: red;
    background: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    margin-left: auto;
    cursor: pointer;
}
.delete-solution-button:hover {
    background: red;
    color: #fff;
}

.save-solution-button {
    display: flex;
    margin-top: 25px;
    margin-right: 25px;
    height: 35px;
    border: 1px solid #5C5CEA;
    color: #5C5CEA;
    background: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    margin-left: auto;
    cursor: pointer;
}

.save-solution-button:hover {
    background: #5C5CEA;
    color: #fff;
}

.editorjs {
    margin-top: 15px;
    width: 750px;
    max-height: 500px;
    margin-left: 5px;
    overflow-y: hidden;
}
.wizard-content-row {
    padding: 4px;
}

.priority-select {
    margin-left: 10px;
    width: 150px;
}

.add-feedback-toggle {
    padding: 5px 10px;
    border: 1px #ccc solid;
    border-radius: 5px;
}

.item-title {
    font-weight: bold;

}

.feedback-textarea {
    width: 350px;
    margin-top: 5px;
    border: 1px #ccc solid;
    border-radius: 4px;
    padding: 5px;
}