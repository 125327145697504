.items {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.9rem;
    max-height: 230px;
    overflow-y: scroll;
    // padding: 0.2rem;
    position: relative;
  }
  
  .item {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.4rem;
    display: block;
    margin: 0;
    padding: 0.2rem 0.4rem;
    text-align: left;
    width: 100%;
    &.is-selected {
        background-color: #f0f0f0;
    }
  }
  .item:hover {
    background-color: #f0f0f0;
  }

  .loader-line {
    height: 3px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    position: sticky;
    bottom: 0px;
}

.loader-line:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: #5B60E3;
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
}