.columns-flex {
    sdisplay: flex;
    flex-direction: column;
}

.rows-flex {
    display: flex;
    flex-direction: row;
}

@keyframes loader-animation {
    0% {
        left: -100%;
    }
    49% {
        left: 100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: -100%;
    }
}
.loader {
    height: 5px;
    width: -webkit-fill-available;
    overflow: hidden;
   
}
.loader.spaced{
    margin: 0 30px;
}
.loader .bar {
    position: relative;
    height: 5px;
    width: 100%;
    background-color: #9ceac3;
    animation-name: loader-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.page-title {
    font-size: 1.6em;
    color: black;
    margin: 15px 15px;
    font-weight: bold;
}

.sort-options {
    margin-left: 15px;
}

.sort-options button {
    border: 1px darkgray solid;
    border-radius: 5px;
    padding: 5px;
    margin-left: 15px;
}
.sort-options button:hover {
    background-color: #eee;
}

.small-font {
    font-size: 0.75em;
}

.notification-content a, .notification-content a:visited, .notification-content a:hover, .notification-content a:active {
    color: #5B60E3 !important;
}